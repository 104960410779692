.grid {
  /* display: block; */
  overflow: auto;
  overflow-y: hidden;
  position: relative;
  z-index: 0;
  outline: none;
  /*white-space: nowrap;*/
  border: 0;
  padding: 0;
  margin: 0;
  border-radius: 3px;
  background-color: transparent;
  -webkit-overflow-scrolling: touch;
}

.gridItem {
  position: absolute;
  align-items: center;
  box-sizing: border-box;
  user-select: none;
  color: #333;
  background: transparent;
  border: 0;
}

.gridItemWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* padding-top: 25%; */
  /* padding-bottom: 25%; */
  width: 100%;
  height: 100%;
  /* background: #fff; */
  border: 1px solid #efefef;
  transition: all 200ms;
  user-select: none;
}

.stylizedHelper {
  cursor: row-resize;
  /*white-space: nowrap;*/
  z-index: 100;
  background-color: transparent;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.15);
}
