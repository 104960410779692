.leaflet-tooltip {
	background-color: rgba(255, 255, 255, 0.9);
	border: none;
	color: rgba(0, 0, 0, 0.7);
  box-shadow: 0px 1px 1px 0px #00000440;
}

.marker-icon {
	width: 15px !important;
	height: 15px !important;
	border: 2px solid #707070;
}
