.loader {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 9999;
}

.loader-page-bg {
  background: #fff;
}

.loader-element-bg {
  background: #f5f5f5;
}

.loader-window {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
}

.loader-element {
  position: relative;
  overflow: hidden;
  z-index: 10;
  min-height: 120px;
}

.loaded-transition {
  opacity: 0;
  transition: opacity 0.3s ease-in;
}

.loaded {
  opacity: 0;
  display: none;
  height: 0;
  width: 0;
  z-index: -99999;
}

.loading-icon {
  top: 50%;
  left: 50%;
  right: 0;
  position: absolute;
  margin: -20px 0 0 -20px;
  bottom: 0;
}

.loading-icon-top {
  top: 64px;
}

.loading-icon-element {
  border-radius: 10%;
}
