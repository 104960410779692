:root {
  --LUI-Outlines-OutlineInput-normal: rgba(0, 0, 0, 0.5);
  --Lui-Focused: 0px 0px 0px 2px #fff, 0px 0px 0px 5px rgba(17, 112, 207, 0.3),
    0px 0px 0px 4px #1170cf;
}

body {
  --page-top-spacing: 0px;

  padding-top: var(--page-top-spacing, 0);
}

.MuiDialog-paper > form {
  /* To prevent the form from adding a wrapping div that disables body scroll */
  display: contents;
}

.webex-aria-live-region {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

#skip-to-main-content-button {
  position: absolute;
  left: -9999px;
  z-index: 99999;
  opacity: 0;
  min-width: 210px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.95);
}

#skip-to-main-content-button:focus {
  left: 16px;
  top: 16px;
  opacity: 1;
}
