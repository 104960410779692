@media (min-width: 1200px) {
  #rs-wrapper.rs-horizontal-menu .rs-sidebar .custom-content {
    display: none;
  }
  #rs-wrapper.rs-horizontal-menu .rs-sidebar {
    overflow: visible !important;
    position: relative;
    z-index: 999;
  }
  #rs-wrapper.rs-horizontal-menu .rs-sidebar {
    width: 100%;
    height: auto;
  }
  #rs-wrapper.rs-horizontal-menu .rs-sidebar:after {
    display: none;
  }
  #rs-wrapper.rs-horizontal-menu .rs-content-wrapper {
    margin-left: 0;
  }
  #rs-wrapper.rs-horizontal-menu .rs-footer.absolute-footer {
    left: 0;
  }

  .rs-horizontal-menu .rs-sidebar .rs-sidebar-nav li.menu-header {
    color: transparent;
    overflow: hidden;
    display: none;
  }
  .rs-horizontal-menu .rs-sidebar .rs-sidebar-nav li.menu-header:hover {
    display: none;
  }
  .rs-horizontal-menu .rs-sidebar .rs-sidebar-nav {
    font-size: 0;
    text-align: center;
  }
  .rs-horizontal-menu .rs-sidebar .rs-sidebar-nav > li.selected,
  .rs-horizontal-menu .rs-sidebar .rs-sidebar-nav > li.active {
    -webkit-box-shadow: inset 0px 2px 0px 0px rgba(129, 199, 132, 1);
    -moz-box-shadow: inset 0px 2px 0px 0px rgba(129, 199, 132, 1);
    box-shadow: inset 0px 2px 0px 0px rgba(129, 199, 132, 1);
  }
  .rs-horizontal-menu .rs-sidebar .rs-sidebar-nav li {
    vertical-align: top;
  }
  .rs-horizontal-menu .rs-sidebar .rs-sidebar-nav ul {
    text-align: left;
  }
  .rs-horizontal-menu .rs-sidebar .rs-sidebar-nav > li {
    display: inline-block;
    font-size: 13px;
  }
  .rs-horizontal-menu .rs-sidebar .rs-sidebar-nav > li {
    width: auto;
    border-bottom-width: 0;
  }
  .rs-horizontal-menu .rs-sidebar .rs-sidebar-nav > li.no-icon-menu {
    display: none;
  }
  .rs-horizontal-menu .rs-sidebar .rs-sidebar-nav > li > a {
    height: auto;
    width: 120px;
    padding: 0;
    line-height: 30px;
    padding: 5px 0 10px 0;
  }
  .rs-horizontal-menu
    .rs-sidebar
    .rs-sidebar-nav
    > li:not(.menu-block-divider):hover {
    overflow: visible;
    background: #2e313d;
  }
  .rs-horizontal-menu .rs-sidebar .rs-sidebar-nav > li > a .rs-icon-menu {
    width: 70px;
    height: 40px;
    line-height: 40px;
    position: relative;
    display: block;
    margin: 0 auto;
  }
  .rs-horizontal-menu .rs-sidebar .rs-sidebar-nav ul {
    position: absolute;
    top: 100%;
    width: 250px;
    left: 0;
    z-index: 1009;
    background: #2e313d;
  }
  .rs-horizontal-menu
    .rs-sidebar
    .rs-sidebar-nav
    > li:not(.disabled):hover
    > a {
    background: rgba(0, 0, 0, 0.1);
  }
  .rs-horizontal-menu .rs-sidebar .rs-sidebar-nav > li > ul,
  .rs-horizontal-menu .rs-sidebar .rs-sidebar-nav > li.active > ul,
  .rs-horizontal-menu .rs-sidebar .rs-sidebar-nav > li > ul > li > ul,
  .rs-horizontal-menu .rs-sidebar .rs-sidebar-nav > li > ul > li.active > ul {
    display: none;
  }
  .rs-horizontal-menu .rs-sidebar .rs-sidebar-nav > li:hover > ul,
  .rs-horizontal-menu .rs-sidebar .rs-sidebar-nav > li.active:hover > ul {
    display: block;
    border-top-width: 0;
  }
  .rs-horizontal-menu .rs-sidebar .rs-sidebar-nav > li > ul > li > ul {
    left: 100%;
    top: 0;
  }
  .rs-horizontal-menu .rs-sidebar .rs-sidebar-nav ul.right-submenus {
    right: 0;
    left: auto;
  }
  .rs-horizontal-menu
    .rs-sidebar
    .rs-sidebar-nav
    > li
    > ul.right-submenus
    > li
    > ul {
    right: 100%;
    left: auto;
  }
  .rs-horizontal-menu .rs-sidebar .rs-sidebar-nav > li > ul > li:hover > ul,
  .rs-horizontal-menu
    .rs-sidebar
    .rs-sidebar-nav
    > li
    > ul
    > li.active:hover
    > ul {
    display: block;
  }
  .rs-horizontal-menu
    .rs-sidebar
    .rs-sidebar-nav
    > li
    > ul
    > li:not(.active):not(.disabled):hover
    > a {
    background: rgba(0, 0, 0, 0.2);
  }
  .rs-horizontal-menu .rs-sidebar .rs-sidebar-nav > li > ul > li > ul > li > a {
    padding-left: 20px;
    background: rgba(0, 0, 0, 0.2);
  }
  .rs-horizontal-menu .rs-sidebar .rs-sidebar-nav > li > a .badge,
  .rs-horizontal-menu .rs-sidebar .rs-sidebar-nav > li > a .badge.over-icon {
    left: 35px;
    top: 4px;
    margin-top: 0;
    right: auto;
  }
  .rs-horizontal-menu .rs-sidebar .rs-sidebar-nav > li > ul > li > a {
    padding-left: 20px;
    background: rgba(0, 0, 0, 0.1);
  }
  .rs-horizontal-menu .rs-sidebar-nav a:not(:only-child):after {
    content: "\f105";
  }
  .rs-horizontal-menu .rs-sidebar-nav .active > a:not(:only-child):after {
    -ms-transform: rotate(0);
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    transform: rotate(0);
  }
  .rs-horizontal-menu .rs-sidebar-nav > li > a:not(:only-child):after,
  .rs-horizontal-menu .rs-sidebar-nav > li.rs-user-sidebar {
    display: none;
  }
}
