/* rubik-regular - latin */
@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  src: local(""), url("rubik-v14-latin-regular.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url("rubik-v14-latin-regular.woff")
      format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* rubik-700 - latin */
@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  src: local(""), url("rubik-v14-latin-700.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url("rubik-v14-latin-700.woff")
      format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* rubik-italic - latin */
@font-face {
  font-family: "Rubik";
  font-style: italic;
  font-weight: 400;
  src: local(""), url("rubik-v14-latin-italic.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url("rubik-v14-latin-italic.woff")
      format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* rubik-700italic - latin */
@font-face {
  font-family: "Rubik";
  font-style: italic;
  font-weight: 700;
  src: local(""), url("rubik-v14-latin-700italic.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("rubik-v14-latin-700italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* poppins-italic - latin */
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 400;
  src: local(""), url("poppins-v15-latin-italic.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("poppins-v15-latin-italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* poppins-regular - latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  src: local(""), url("poppins-v15-latin-regular.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("poppins-v15-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* poppins-700 - latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  src: local(""), url("poppins-v15-latin-700.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url("poppins-v15-latin-700.woff")
      format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* poppins-700italic - latin */
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 700;
  src: local(""), url("poppins-v15-latin-700italic.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("poppins-v15-latin-700italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* playfair-display-regular - latin */
@font-face {
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: 400;
  src: local(""),
    url("playfair-display-v22-latin-regular.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("playfair-display-v22-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* playfair-display-700 - latin */
@font-face {
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: 700;
  src: local(""), url("playfair-display-v22-latin-700.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("playfair-display-v22-latin-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* playfair-display-italic - latin */
@font-face {
  font-family: "Playfair Display";
  font-style: italic;
  font-weight: 400;
  src: local(""), url("playfair-display-v22-latin-italic.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("playfair-display-v22-latin-italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* playfair-display-700italic - latin */
@font-face {
  font-family: "Playfair Display";
  font-style: italic;
  font-weight: 700;
  src: local(""),
    url("playfair-display-v22-latin-700italic.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("playfair-display-v22-latin-700italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* open-sans-regular - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: local(""), url("open-sans-v26-latin-regular.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("open-sans-v26-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-700 - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  src: local(""), url("open-sans-v26-latin-700.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url("open-sans-v26-latin-700.woff")
      format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-italic - latin */
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 400;
  src: local(""), url("open-sans-v26-latin-italic.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("open-sans-v26-latin-italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-700italic - latin */
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 700;
  src: local(""), url("open-sans-v26-latin-700italic.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("open-sans-v26-latin-700italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-regular - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: local(""), url("roboto-v29-latin-regular.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("roboto-v29-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-italic - latin */
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 400;
  src: local(""), url("roboto-v29-latin-italic.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url("roboto-v29-latin-italic.woff")
      format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-700 - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: local(""), url("roboto-v29-latin-700.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url("roboto-v29-latin-700.woff")
      format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-700italic - latin */
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 700;
  src: local(""), url("roboto-v29-latin-700italic.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("roboto-v29-latin-700italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-regular - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: url("montserrat-v18-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("montserrat-v18-latin-regular.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("montserrat-v18-latin-regular.woff2") format("woff2"),
    /* Super Modern Browsers */ url("montserrat-v18-latin-regular.woff")
      format("woff"),
    /* Modern Browsers */ url("montserrat-v18-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("montserrat-v18-latin-regular.svg#Montserrat") format("svg"); /* Legacy iOS */
}
/* montserrat-italic - latin */
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 400;
  src: url("montserrat-v18-latin-italic.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("montserrat-v18-latin-italic.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("montserrat-v18-latin-italic.woff2") format("woff2"),
    /* Super Modern Browsers */ url("montserrat-v18-latin-italic.woff")
      format("woff"),
    /* Modern Browsers */ url("montserrat-v18-latin-italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("montserrat-v18-latin-italic.svg#Montserrat")
      format("svg"); /* Legacy iOS */
}
/* montserrat-700 - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  src: url("montserrat-v18-latin-700.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("montserrat-v18-latin-700.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("montserrat-v18-latin-700.woff2") format("woff2"),
    /* Super Modern Browsers */ url("montserrat-v18-latin-700.woff")
      format("woff"),
    /* Modern Browsers */ url("montserrat-v18-latin-700.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("montserrat-v18-latin-700.svg#Montserrat")
      format("svg"); /* Legacy iOS */
}
/* montserrat-700italic - latin */
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 700;
  src: url("montserrat-v18-latin-700italic.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("montserrat-v18-latin-700italic.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("montserrat-v18-latin-700italic.woff2") format("woff2"),
    /* Super Modern Browsers */ url("montserrat-v18-latin-700italic.woff")
      format("woff"),
    /* Modern Browsers */ url("montserrat-v18-latin-700italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("montserrat-v18-latin-700italic.svg#Montserrat") format("svg"); /* Legacy iOS */
}
/* merriweather-regular - latin */
@font-face {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 400;
  src: local(""), url("merriweather-v25-latin-regular.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("merriweather-v25-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* merriweather-italic - latin */
@font-face {
  font-family: "Merriweather";
  font-style: italic;
  font-weight: 400;
  src: local(""), url("merriweather-v25-latin-italic.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("merriweather-v25-latin-italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* merriweather-700italic - latin */
@font-face {
  font-family: "Merriweather";
  font-style: italic;
  font-weight: 700;
  src: local(""), url("merriweather-v25-latin-700italic.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("merriweather-v25-latin-700italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* merriweather-700 - latin */
@font-face {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 700;
  src: local(""), url("merriweather-v25-latin-700.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("merriweather-v25-latin-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
